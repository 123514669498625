/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    a: "a",
    img: "img"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "http://www.rtve.es/television/documentales/comprar-tirar-comprar/directo/"
  }, React.createElement(_components.img, {
    src: "images/comprar-tirar-comprar.jpg",
    alt: "",
    title: "comprar tirar comprar"
  }))), "\n", React.createElement(_components.p, null, "Así se llama el documental sobre ", React.createElement(_components.a, {
    href: "http://es.wikipedia.org/wiki/Obsolescencia_planificada",
    title: "Obsolescencia Planificada"
  }, "obsolescencia programada"), " que nos ofreció anoche La 2 de TVE. Os dejo un enlace donde podreis ver el documental durante las siguientes dos semanas. Y una ", React.createElement(_components.a, {
    href: "http://www.obsoletos.org/2011/01/comprar-tirar-comprar/"
  }, "reseña"), " de este documental que he encontrado en uno de mis blogs favoritos que trata exactamente de este tema."), "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "http://www.rtve.es/television/documentales/comprar-tirar-comprar/directo/"
  }, "http://www.rtve.es/television/documentales/comprar-tirar-comprar/directo/")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
